// Generated by CoffeeScript 1.9.3

/*
jQuery Modal
Copyright 2014 Kevin Sylvestre
1.1.9
 */

(function() {
  "use strict";
  var $, Animation, Modal,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  $ = jQuery;

  Animation = (function() {
    function Animation() {}

    Animation.transitions = {
      "webkitTransition": "webkitTransitionEnd",
      "mozTransition": "mozTransitionEnd",
      "oTransition": "oTransitionEnd",
      "transition": "transitionend"
    };

    Animation.transition = function($el) {
      var el, ref, result, type;
      el = $el[0];
      ref = this.transitions;
      for (type in ref) {
        result = ref[type];
        if (el.style[type] != null) {
          return result;
        }
      }
    };

    Animation.execute = function($el, callback) {
      var transition;
      transition = this.transition($el);
      if (transition != null) {
        return $el.one(transition, callback);
      } else {
        return callback();
      }
    };

    Animation.hide = function($el, klass) {
      if (klass == null) {
        klass = 'fade';
      }
      return $el.addClass(klass);
    };

    Animation.show = function($el, klass) {
      if (klass == null) {
        klass = 'fade';
      }
      return $el.removeClass(klass);
    };

    Animation.enable = function($el, klass) {
      return $el.addClass(klass);
    };

    Animation.disable = function($el, klass) {
      return $el.removeClass(klass);
    };

    return Animation;

  })();

  Modal = (function() {
    Modal.modal = function($el, options) {
      var data;
      if (options == null) {
        options = {};
      }
      data = $el.data('modal');
      if (!data) {
        data = new Modal($el, options);
        $el.data('modal', data);
      }
      return data;
    };

    Modal.prototype.$ = function(selector) {
      return this.$modal.find(selector);
    };

    function Modal($modal, settings) {
      if (settings == null) {
        settings = {};
      }
      this.show = bind(this.show, this);
      this.hide = bind(this.hide, this);
      this.observe = bind(this.observe, this);
      this.keyup = bind(this.keyup, this);
      this.close = bind(this.close, this);
      this.remove = bind(this.remove, this);
      this.$ = bind(this.$, this);
      this.$modal = $modal;
      this.settings = settings;
      this.$vignette = $("<div class='vignette fade'></div>");
    }

    Modal.prototype.remove = function() {
      this.$modal.remove();
      return this.$vignette.remove();
    };

    Modal.prototype.close = function(event) {
      if (event != null) {
        event.preventDefault();
      }
      if (event != null) {
        event.stopPropagation();
      }
      return this.hide();
    };

    Modal.prototype.keyup = function(event) {
      if (event.target.form != null) {
        return;
      }
      if (event.which === 27) {
        return this.close();
      }
    };

    Modal.prototype.observe = function(method) {
      var modaler, that;
      if (method == null) {
        method = 'on';
      }
      if (!this.settings["static"]) {
        that = this;
        modaler = function(event) {
          if (event.target === this) {
            return that.close(event);
          }
        };
        this.$modal.parent('.modaler')[method]('click', modaler);
        $(document)[method]('keyup', this.keyup);
        this.$vignette[method]('click', this.close);
        return this.$modal[method]('click', '[data-dismiss="modal"]', this.close);
      }
    };

    Modal.prototype.hide = function() {
      var alpha, omega;
      alpha = (function(_this) {
        return function() {
          return _this.observe('off');
        };
      })(this);
      omega = (function(_this) {
        return function() {
          Animation.disable($(document.body), 'modaled');
          if (_this.$modal.parent('.modaler').length) {
            _this.$modal.unwrap();
          }
          _this.$modal.trigger('hidden');
          _this.$vignette.remove();
          return _this.$modal.hide();
        };
      })(this);
      alpha();
      this.vignette('hide');
      return this.modal('hide', omega);
    };

    Modal.prototype.show = function() {
      var alpha, omega;
      omega = (function(_this) {
        return function() {
          return _this.observe('on');
        };
      })(this);
      alpha = (function(_this) {
        return function() {
          Animation.enable($(document.body), 'modaled');
          if (!_this.$modal.parent('.modaler').length) {
            _this.$modal.wrap("<div class='modaler'></div>");
          }
          _this.$modal.trigger('shown');
          $(document.body).append(_this.$vignette);
          return _this.$modal.show();
        };
      })(this);
      alpha();
      this.vignette('show');
      return this.modal('show', omega);
    };

    Modal.prototype.modal = function(method, callback) {
      this.$vignette.position();
      Animation[method](this.$modal);
      if (callback != null) {
        return Animation.execute(this.$modal, callback);
      }
    };

    Modal.prototype.vignette = function(method, callback) {
      this.$vignette.position();
      Animation[method](this.$vignette);
      if (callback != null) {
        return Animation.execute(this.$vignette, callback);
      }
    };

    return Modal;

  })();

  $.fn.extend({
    modal: function(option) {
      if (option == null) {
        option = {};
      }
      return this.each(function() {
        var $this, action, options;
        $this = $(this);
        options = $.extend({}, $.fn.modal.defaults, typeof option === "object" && option);
        action = typeof option === "string" ? option : option.action;
        if (action == null) {
          action = "show";
        }
        return Modal.modal($this, options)[action]();
      });
    }
  });

}).call(this);
